import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Typography, Card, CardMedia, Grid, CardContent, Container, Rating, InputBase } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { BuyButton, ProductDetails, ProductImageContainer, ProductPaper, useStyles } from "../../styles";
import { awcg_domain_u1 } from "../../utils";
import CategoryChooser from "../../components/StoreFilter";

const ProductPage = () => {
  const classes = useStyles();
  const { id } = useParams();
  console.log("in Dynamic Page ID",id)
  console.log(useSelector((state) =>
    state.dynamicStoreData.products.find((p) => p.ASIN === id)
  ))
  const product = useSelector((state) =>
    state.dynamicStoreData.products.find((p) => p.ASIN === id)
  );

  // if (!product) return <Typography>Product not found.</Typography>;

  return (
    <div>
      {/* Navbar */}
      <div className="store_pageHead">
        <div className="store_innerHeader">
          <div className="store_logo">
            <div className="pagetitletext">{awcg_domain_u1}</div>
          </div>


          <InputBase
            placeholder="Search…"
            className={classes.searchInput}
            // onChange={}
          />
        </div>
      </div>
      {/* <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6">{siteName}</Typography>
          <InputBase
            placeholder="Search…"
            className={classes.searchInput}
            onChange={handleSearch}
          />
        </Toolbar>
      </AppBar> */}
      <div className="store_containerMain">
        <div className="store_containerMainInner">      
          <div className="store_pageBody">      
            <div className="store_menuLeft">    
              <div className="store_categories_filter">    
              {/* <CategoryChooser/> */}
              </div>  
            </div>  
            <div className="store_pageContent">      
              {/* <Grid container spacing={3} style={{ display: "flex", flexWrap: "wrap", alignItems:"stretch" }}> */}
                
                  <ProductPaper>
                  <ProductImageContainer>
                    <img src={product.Images.Primary.Small.URL} alt={product.ItemInfo.Title.DisplayValue} />
                  </ProductImageContainer>
                  <ProductDetails>
                  <Typography variant="h6">{product.ItemInfo.Title.DisplayValue}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {product.Offers.Summaries[0].Price.DisplayAmount}
                  </Typography>
                  <Rating value={product.Rating.AverageRating} readOnly />
                  <BuyButton startIcon={<ShoppingCartIcon />}>Buy Now</BuyButton>
                  </ProductDetails>
                </ProductPaper>
            
              {/* </Grid> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  
      
    
  );
};

export default ProductPage;