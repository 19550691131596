import { AppBar, Toolbar, InputBase, Drawer, List, ListItem, ListItemText, Grid, Paper, Typography, Box, Button, Rating } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";

export const useStyles = makeStyles({
    root: { display: "flex" },
    appBar: { zIndex: 1400 },
    searchInput: { marginLeft: "auto", flex: 1, padding: "0 10px", background: "#fff", borderRadius: "4px" },
    drawer: { width: 240 },
    drawerPaper: { width: 240 },
    content: { flexGrow: 1, padding: "20px" },
    card: { maxWidth: 345 },
    media: { height: 140 },
  });
  
export const ProductPaper = styled(Paper)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
    height: "100%",
    transition: "all 0.3s ease",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    "&:hover": {
        boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
    },
}));
    
export const ProductImageContainer = styled("div")({
    width: "100%",
    paddingTop: "100%", // Maintains 1:1 aspect ratio
    position: "relative",
    overflow: "hidden",
    "& img": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        transition: "transform 0.3s ease",
    },
    "&:hover img": {
        transform: "scale(1.1)", // Zoom on hover
    },
});

export const ProductDetails = styled("div")({
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    flexGrow: 1,
});


export const BuyButton = styled(Button)(({ theme }) => ({
    marginTop: "auto",
    backgroundColor: "#fff",
    color: "#F96E46",
    border: `1px solid #F96E46`,
    transition: "all 0.3s ease",
    "&:hover": {
        backgroundColor: "#F96E46",
        color: "#fff",
    },
}));