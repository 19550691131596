import React, { useState } from "react";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { Slider, Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { filterByCategory, filterByPrice, filterByRating } from "../../state/actions/dynamicstoreData";


// Helper to map categories for SimpleTreeView
const mapCategoriesForTreeView = (categories) => {
  const mapNode = (nodes, level = 0) =>
    nodes.map((node, index) => ({
      id: `${node.name}`, // Unique id for each node
      label: node.name,
      children: node.children ? mapNode(node.children, level + 1) : [],
    }));
  const mapNodeWithAll = [{id: "All_Categories", label: "All Categories", children:[]}, ...mapNode(categories)]  
    console.log("mapnode:",mapNodeWithAll)
  return mapNodeWithAll;
};

const CategoryChooser = () => {
  const [selectedItems, setSelectedItems] = useState([])
  const categories = useSelector((state) => state.dynamicStoreData.categories);
  const selectedCategories = useSelector((state) => state.dynamicStoreData.selectedCategories);
  const priceRange = useSelector((state) => state.dynamicStoreData.priceRange);
  const ratingRange = useSelector((state) => state.dynamicStoreData.ratingRange);
  const dispatch = useDispatch();

  const handleCategoryChange = (
    event,
    itemId,
    isSelected,
  ) => {
   
      console.log("selected",itemId)
      console.log(JSON.stringify(selectedCategories) === JSON.stringify([itemId]),JSON.stringify(selectedCategories),JSON.stringify([itemId]),"selected",itemId)
      if(itemId === "All_Categories"){
        dispatch(filterByCategory([]));
        setSelectedItems([])
      }
      else if(isSelected){
        dispatch(filterByCategory([itemId]));
        setSelectedItems([itemId])
       
      } else{
        dispatch(filterByCategory([]));
        setSelectedItems([])
      }
    
  };

  const handlePriceChange = (event, newValue) => {
    dispatch(filterByPrice({ min: newValue[0], max: newValue[1] }));
  };

  const handleRatingChange = (event, newValue) => {
    dispatch(filterByRating({ min: newValue[0], max: newValue[1] }));
  };

  const treeData = mapCategoriesForTreeView(categories);

  return (
    <div>
      {/* Category Tree */}
      <Typography variant="h6">Categories</Typography>
      <RichTreeView
        selectedItems={selectedItems}
        items={treeData}
        onItemSelectionToggle={handleCategoryChange}
      />

      {/* Price Filter */}
      <Typography variant="h6" sx={{ mt: 3 }}>
        Price Filter
      </Typography>
      <Box>
        {/* Histogram above slider (example) */}
        {/* <Box sx={{ height: 50, display: "flex", alignItems: "flex-end" }}>
          {[10, 20, 15, 5, 30].map((count, index) => (
            <Box
              key={index}
              sx={{
                width: "20%",
                height: `${count * 5}px`,
                backgroundColor: "lightgray",
                marginRight: "2px",
              }}
            />
          ))}
        </Box> */}
        <Slider
          valueLabelDisplay="auto"
          value={[priceRange.min, priceRange.max]}
          min={0}
          max={500}
          step={10}
          onChange={handlePriceChange}
        />
      </Box>

      {/* Rating Filter */}
      <Typography variant="h6" sx={{ mt: 3 }}>
        Rating Filter
      </Typography>
      <Slider
        valueLabelDisplay="auto"
        value={[ratingRange.min, ratingRange.max]}
        min={0}
        max={5}
        step={0.1}
        onChange={handleRatingChange}
      />
    </div>
  );
};

export default CategoryChooser;
