import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


import { preferencesReducer } from './preferences';
import { domainDataReducer } from './domainData';
import { rssDataReducer } from './rssData';
import { dynamicDataReducer } from './dynamicData';
import storeReducer from './dynamicStoreData';



export default combineReducers({
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  ),
  domainData: persistReducer(
    { key: 'domainData', storage },
    domainDataReducer
  ),
  dynamicData: persistReducer(
    { key: 'dynamicData', storage },
    dynamicDataReducer
  ),
  dynamicStoreData: persistReducer(
    { key: 'store', storage },
    storeReducer
  ),  
  rssData: persistReducer(
    { key: 'rssData', storage },
    rssDataReducer
  ),
  
});
