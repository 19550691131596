import { useState, useEffect } from 'react';
import { setDynamicData } from '../state/actions/dynamicData';
import { useDispatch, useSelector } from 'react-redux';

export const useDynamicData = (dataTopic) => {
    const data = useSelector((state) => state.dynamicData)
    let requestedData = data?.[dataTopic]
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch()

    const fetchData = async () => {
        setIsLoading(true);
        console.log("fetching dynamic data")
        try {
            fetch('https://any-dns.co.uk/aw_api/api/dynamicData?category=' + dataTopic,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => response.json())
                .then(receivedData => {
                    dispatch(setDynamicData(dataTopic,receivedData))
                })
        
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
        setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!requestedData) {
        fetchData();
        }
    }, [dataTopic]); // Fetch data whenever dataTopic changes

    return { requestedData, isLoading, fetchData };
};