import { createAction } from "redux-act";

export const FILTER_BY_CATEGORY = createAction("FILTER_BY_CATEGORY");
export const FILTER_BY_PRICE = createAction("FILTER_BY_PRICE");
export const FILTER_BY_RATING = createAction("FILTER_BY_RATING");
export const SEARCH_PRODUCTS = createAction("SEARCH_PRODUCTS");

export const filterByCategory = (selectedCategories) => (dispatch) => {
  return dispatch(FILTER_BY_CATEGORY(selectedCategories));
};

export const filterByPrice = (priceRange) => (dispatch) => {
  return dispatch(FILTER_BY_PRICE(priceRange));
};

export const filterByRating = (ratingRange) => (dispatch) => {
  return dispatch(FILTER_BY_RATING(ratingRange));
};

export const searchProducts = (queryString) => (dispatch) => {
  return dispatch(SEARCH_PRODUCTS(queryString));
};