import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppBar, Toolbar, InputBase, Drawer, List, ListItem, ListItemText, Grid, Paper, Typography, Box, Button, Rating } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { display, maxWidth, styled } from "@mui/system";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CategoryChooser from "../../components/StoreFilter";
import { awcg_domain_u1 } from "../../utils";
import { searchProducts } from "../../state/actions/dynamicstoreData";
import { BuyButton, ProductDetails, ProductImageContainer, ProductPaper, useStyles } from "../../styles";


// Styles

const StorePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux State
  console.log("Filtered Products",useSelector((state) => state.dynamicStoreData))
  const { filteredProducts } = useSelector((state) => state.dynamicStoreData);

    const handleSearch = (query) => {
        dispatch(searchProducts(query));
    };

    const handleProductClick = (id) => {
        navigate(`/product/${id}`);
    };

  return (
    <div>
      {/* Navbar */}
      <div className="store_pageHead">
        <div className="store_innerHeader">
          <div className="store_logo">
            <div className="pagetitletext">{awcg_domain_u1}</div>
          </div>


          <InputBase
            placeholder="Search…"
            className={classes.searchInput}
            onChange={handleSearch}
          />
        </div>
      </div>
      {/* <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6">{siteName}</Typography>
          <InputBase
            placeholder="Search…"
            className={classes.searchInput}
            onChange={handleSearch}
          />
        </Toolbar>
      </AppBar> */}
      <div className="store_containerMain">
        <div className="store_containerMainInner">      
          <div className="store_pageBody">      
            <div className="store_menuLeft">    
              <div className="store_categories_filter">    
              <CategoryChooser/>
              </div>  
            </div>  
            <div className="store_pageContent">      
              <Grid container spacing={3} style={{ display: "flex", flexWrap: "wrap", alignItems:"stretch" }}>
                {filteredProducts.map((product) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={product.ASIN}>
                    <ProductPaper onClick={() => handleProductClick(product.ASIN)}>
                      <ProductImageContainer>
                        <img src={product.Images.Primary.Small.URL} alt={product.ItemInfo.Title.DisplayValue} />
                      </ProductImageContainer>
                      <ProductDetails>
                      <Typography variant="h6">{product.ItemInfo.Title.DisplayValue}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {product.Offers.Summaries[0].Price.DisplayAmount}
                      </Typography>
                      <Rating value={product.Rating.AverageRating} readOnly />
                      <BuyButton startIcon={<ShoppingCartIcon />}>Buy Now</BuyButton>
                      </ProductDetails>
                    </ProductPaper>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorePage;