import { createReducer } from "redux-act";
import storeData from "../../../tests/data/vr_products.json";
import { FILTER_BY_CATEGORY, FILTER_BY_PRICE, FILTER_BY_RATING, SEARCH_PRODUCTS } from "../../actions/dynamicstoreData";

// Helper: Map categories into a nested tree structure
const mapCategories = (products) => {
  const categoryTree = {};

  products.forEach((product) => {
    const {
      ItemInfo: {
        Category: { MainCategory, SubCategory, SpecificCategory },
      },
    } = product;

    if (!categoryTree[MainCategory]) {
      categoryTree[MainCategory] = {};
    }
    if (!categoryTree[MainCategory][SubCategory]) {
      categoryTree[MainCategory][SubCategory] = new Set();
    }
    categoryTree[MainCategory][SubCategory].add(SpecificCategory);
  });

  // Convert Sets to Arrays for easier handling in React
  return Object.entries(categoryTree).map(([mainCategory, subCategories]) => ({
    name: mainCategory,
    children: Object.entries(subCategories).map(([subCategory, specificCategories]) => ({
      name: subCategory,
      children: Array.from(specificCategories).map((specificCategory) => ({
        name: specificCategory,
      })),
    })),
  }));
};


// Helper: Determine whether any filters are active
const areFiltersActive = (state) => {
  const { searchQuery, priceRange, ratingRange, selectedCategories } = state;

  // Filters are active if there's a search query, active price or rating range, or selected categories
  return (
    searchQuery ||
    priceRange.min > 0 ||
    priceRange.max < 500 || // Example max range
    ratingRange.min > 0 ||
    ratingRange.max < 5 ||  // Example max range
    selectedCategories.length > 0
  );
};

// Helper: Filter products by multiple criteria
const filterProducts = (state) => {
  const { products, searchQuery, priceRange, ratingRange, selectedCategories } = state;

  // If no filters are active, return all products
  if (!areFiltersActive(state)) {
    return products;
  }

  // Otherwise, filter the products
  return products.filter((product) => {
    const matchesSearch =
      !searchQuery ||
      product.ItemInfo.Title.DisplayValue.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesPrice =
      product.Offers.Summaries[0].Price.Amount >= priceRange.min &&
      product.Offers.Summaries[0].Price.Amount <= priceRange.max;
  
    const matchesRating =
      product.Rating.AverageRating >= ratingRange.min &&
      product.Rating.AverageRating <= ratingRange.max;
  
    const matchesCategory =
      !selectedCategories.length ||
      selectedCategories.some((cat) =>
        [
          product.ItemInfo.Category.MainCategory,
          product.ItemInfo.Category.SubCategory,
          product.ItemInfo.Category.SpecificCategory
        ].includes(cat)
      );  
    return matchesSearch && matchesPrice && matchesRating && matchesCategory;
  });
  
};

// Initial State
const initialState = {
  categories: mapCategories(storeData.products),
  products: storeData.products,
  priceRange: { min: 0, max: 500 },
  ratingRange: { min: 0, max: 5 },
  searchQuery: null,
  selectedCategories: [],
  filteredProducts: storeData.products, // Initially show all products
};

// Reducer
const storeReducer = createReducer(
  {
    // Category Filter
    [FILTER_BY_CATEGORY]: (state, selectedCategories) => ({
      ...state,
      selectedCategories,
      filteredProducts: filterProducts({ ...state, selectedCategories }),
    }),

    // Price Filter
    [FILTER_BY_PRICE]: (state, priceRange) => ({
      ...state,
      priceRange,
      filteredProducts: filterProducts({ ...state, priceRange }),
    }),

    // Rating Filter
    [FILTER_BY_RATING]: (state, ratingRange) => ({
      ...state,
      ratingRange,
      filteredProducts: filterProducts({ ...state, ratingRange }),
    }),

    // Search Filter
    [SEARCH_PRODUCTS]: (state, searchQuery) => ({
      ...state,
      searchQuery,
      filteredProducts: filterProducts({ ...state, searchQuery }),
    }),
  },
  initialState
);

export default storeReducer;