import React, { useEffect } from 'react';
import { useDynamicData } from '../../hooks/useDynamicData';
import { useSelector } from 'react-redux';

const AW_DataWrapper = ( { dataTopic, children }) => {
  const { isLoading, fetchData } = useDynamicData(dataTopic);
  const data = useSelector((state) => state.dynamicData?.[dataTopic])
  console.log(dataTopic,": ",data)
useEffect(() => {
  if (!data) {
    // If no data is present, fetch it
    fetchData();
  }

}, [dataTopic])

  if(!data){
    return <div>Loading...</div>;
  }
  if(data){
    return (
      <>
        {React.Children.map(children, child => {
          return React.cloneElement(child, { data });
        })}
      </>
    );
  }
};

export default AW_DataWrapper;